@import "../../_variables";

.nfc-button {
    margin-left: 25px;
    padding: 5px;
    border: 1px solid $body-bg;
    border-radius: 4px;
    user-select: none; }
.nfc-button--active {
    background: $success;
    border: 1px solid $success;
    .nfc-icon {
        path {
            fill: #fff; } } }
.nfc-icon {
    width: 26px;
    height: 26px; }
