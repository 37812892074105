@import '../../../variables';

.customer-info-tab {
  border: 1px solid $grey;
  padding: 5px;
  h4 {
    color: $body-bg; }
  .gate-access-color-code {
    min-height: 250px;
    height: 100%;
    &--success {
      background-color: $success; }
    &--fail {
      background-color: $danger; } } }
@media screen and ( max-width:  576px ) {
  .customer-info-tab {
    flex-direction: column-reverse; } }


