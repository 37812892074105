@import "../../_variables";

.Avatar {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  position: relative; }

.Avatar-input {
  display: none; }
.avatar-edit {
  position: absolute;
  top: 0;
  right: 0; }
.avatar-edit-icon {
  path {
    fill: $body-bg; } }
.uploading::after {
  content: "Uploading...";
  position: absolute;
  font-weight: 500;
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(#010101,0.3);
  backdrop-filter: blur(2px);
  border-radius: 50%; }
