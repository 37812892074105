@import '../../variables';

.ItemButton {
  background-color: $light;
  width: 100%;

  &:hover {
    background-color: $light; }

  .name {
    font-size: 16px;
    font-weight: bold;
    color: $dark; }
  .text-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .price-container {
      margin-top: auto; } }
  .price {
    font-size: 22px;
    color: $dark;
    font-weight: bold;
    border-top: 2px solid $dark; }
  .col-6 {
    padding: 0 5px; } }
.img-container {
  object-fit: contain; }
.img {
  width: 100%;
  height: 175px; }
