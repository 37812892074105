.tier-button-container {
    margin-bottom: 15px; }

.ticket-tier-select-area {
    display: flex;
    width: 100%;
    max-width: 600px;
    flex-wrap: wrap; }

.customer-payment-type {
    width: 100%;
    max-width: 300px;
    margin-right: 15px;
    margin-bottom: 20px; }

.payment-and-creation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .creation-type {
        margin-bottom: 20px;
        label {
            margin-bottom: 0px;
            margin-right: 15px;
            cursor: pointer; } } }
.auto-generate-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
