.method-modal {
    .method-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }
    .method-btn {
        width: 150px;
        height: 150px;
        border: none;
        border-radius: 5px;
        margin: 10px;
        font-weight: bold;
        font-size: 20px;
        color: white;
        background-color: #063A49; }
    .body-title {
        color: #063A49; }
    .modal-dialog {
        max-width: 550px;
        width: 95%;
        margin: auto; } }
