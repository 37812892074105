$body-bg: #063A49;
$primary: #FFCD67;
$secondary: #FFFFFF;
$danger: #dc3545;
$light: #f8f9fa;
$headings-color: $primary;
$text-muted: #81B5BD;
$text-muted-faded: #81B5BD55;
$text-muted-dark: #7D99A1;
$dark: $body-bg;
$dark-faded: #063A4966;
$watermelon: #F4BFB3;
$grey: #EBEBEB;
$transparent: #FFFFFF00;
$success: #28a745;
