@import '../../variables';

.AccessControl {
  height: calc(100vh - 70px);
  .tabs {
    white-space: nowrap; }

  .table-container {
    height: 450px; }

  .nav {
    .nav-link {
      color: $body-bg;

      &.active {
        color: $light;
        background-color: $body-bg; } } } }
