$btn-width: 100px;
$btn-height: 75px;

.TopUpOrCashOut {
  .btn-square {
    width: $btn-width;
    height: $btn-height; } }
.comp-cash-div {
  display: flex;
  align-items: center;
  user-select: none; }
.comp-cash-btn {
  height: 50px;
  width: 50px;
  border: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  &--active {
    background-color: #063A49;
    border: 1px solid #063A49; } }
.comp-cash-label {
  font-size: 18px;
  font-weight: 500;
  padding-left: 15px;
  cursor: pointer; }
.check-icon {
  color: white; }
.cash-value-area {
  display: flex;
  flex-wrap: nowrap; }
.cash-value-input {
  flex: 1; }
.cash-value-reset {
  height: $btn-height;
  width: $btn-width; }


