@import "./../../_variables";

.autocomplete {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    user-select: none;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0px 0px 3px 1px $grey;
    border: 1px solid $grey;
    border-top: none;
    z-index: 10;
    background: white;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    .autocomplete__item {
        padding: 7px 10px;
        cursor: pointer; }
    .autocomplete__item:hover, .autocomplete__item--selected {
        background: $body-bg;
        color: white; } }
.autocomplete__icon-container {
        display: flex;
        align-items: center;
        height: calc(100% - 2px);
        width: 25px;
        position: absolute;
        top: 1px;
        right: 1px;
        padding: 0px 5px;
        background: white;
        cursor: pointer; }
.autocomplete__dropdown {
    transform: rotate(0deg);
    transition: transform 0.2s; }
.autocomplete__dropdown--expanded {
    transform: rotate(180deg); }
.autocomplete__clear-container {
    right: 26px; }
.autocomplete__count {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translate(100%,-50%); }

@media screen and ( max-width: 768px ) {
    .autocomplete__count {
        top: unset;
        right: unset;
        left: 0;
        bottom: -5px;
        transform: translate(0,100%); } }


