@import '../../variables';

.CustomerModal {
  .customerModal-header {
    position: relative; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $body-bg; } }
.access-check {
  display: flex;
  align-items: center;
  .access-check-text {
    margin-right: 7px; }
  .access-check-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    &--danger {
      background-color: $danger; }
    &--success {
      background-color: $success; }
    &--neutral {
      background-color: $body-bg; } } }

.staff-flag {
  padding: 5px;
  background-color: $dark;
  color: white;
  text-align: center; }
.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  path {
    fill: $danger; } }
