$nav-height: 36px;
.Vendors {
  height: calc(100vh - 70px);
  overflow: hidden;

  .card {
    height: 100%; }

  $title-height: 56px;
  .title {
    height: $title-height; }

  .menus {
    height: calc(100% - (#{$title-height} + #{$nav-height})); } }
.vendor-id {
  position: fixed;
  bottom: 0px;
  left: 0px;
  color: white; }

.MenusPage {
  .nav {
    height: $nav-height; } }
