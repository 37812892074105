@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@1,300;1,500;1,700&family=Poppins:wght@300;500;700&display=swap');
@import './variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.card {
  .card-body,
  & h1,
  & h2 {
    color: $body-bg; } }

.nav .nav-link:not(.active) {
  background-color: rgba(0, 0, 0, .1); }

.error {
  color: $danger;
  font-size: 12px; }

::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #738E97;
  border-radius: 10px; }

.clickable {
  cursor: pointer;
  color: #007bff; }

.autocomplete-container {
  position: relative; }

.camera-popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $body-bg;
  z-index: 10; }

.qr-code-close-icon {
  position: fixed;
  top: 12px;
  right: 12px;
  cursor: pointer;
  z-index: 2;
  path {
    fill: $danger; } }

.qr-code-icon {
  cursor: pointer;
  margin-left: 25px; }
