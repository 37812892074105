@import '../../../variables';

.CashPage {
  height: 100%;

  .nav {
    .nav-link {
      color: $body-bg;

      &.active {
        color: $light;
        background-color: $body-bg; } } } }
