@import '../../variables';

.tierButton {
    padding: 10px 15px;
    background-color: $transparent;
    color: $dark;
    font-weight: 600;
    border: 1px solid $dark;
    cursor: pointer;
    border-radius: 3px;
    &--selected {
        background-color: $success;
        color: white;
        border: 1px solid $success; }
    &:disabled {
        background-color: gray;
        color: lightgray;
        cursor: default;
        border: 1px solid gray;
        text-decoration: line-through; } }
