@import "./../../_variables";
.custom-toast-container {
    position: fixed;
    top: 10px;
    right: 10px;
    min-width: 300px;
    .toast {
        background-color: white;
        box-shadow: 0px 0px 8px 1px $grey;
        border: 1px solid $grey; }
    .custom-toast {
        img {
            width: 20px; }
        .toast-header {
            justify-content: space-between;
            font-size: 18px; }
        .toast-body {
            font-size: 16px; } } }
