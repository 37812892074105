// Custom styles and themes
@import './variables';

$border-radius: 0;
$input-border-radius: 1px;

$font-family-base: 'Poppins', sans-serif;
$headings-font-family: 'Newsreader', serif;

$col-width: 8.3333333333333%;

$theme-colors: ('transparent': $transparent, 'watermelon': $watermelon, 'muted-dark': $text-muted-dark, 'grey': $grey, 'muted-faded': $text-muted-faded, 'dark-faded': $dark-faded);

@import '~bootstrap/scss/bootstrap';
