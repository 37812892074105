@import '../../../variables';

.UsersPage {
  .table-container {
    height: 550px; }
  label {
    margin-bottom: 5px; }
  .nav {
    .nav-link {
      color: $body-bg;

      &.active {
        color: $light;
        background-color: $body-bg; } } } }
