.rfid-input-and-status {
    display: flex;
    align-items: center; }
.rfid-status {
    width: 200px;
    height: 30px;
    border-radius: 100px;
    margin-right: 7px;
    &--active {
        background-color: #009dff; }
    &--disabled {
        background-color: white; } }
